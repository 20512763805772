.content-banner{
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 33vh;
} //content-banner

.content-main{
  &__title{
    margin-bottom: 40px;
    font-size: 55px;
    font-weight: 700;
    letter-spacing: -.03em;
    color: #181818;
  }

  &__text{
    p{
      font-size: 20px;
      line-height: 30px;
      letter-spacing: -.02em;
      font-weight: 400;
      color: #878a8e;
    }
  }
}
