// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------
.section-full{
  width:100%;
}

.section-limit{
  width:100%;
  max-width:$max-width;
  margin: 0 auto;

  @at-root #{&}_relative{
    position: relative;
  } //_relative
  @at-root #{&}_mini{
    max-width: $max-width-mini;
  } //_mini
  @at-root #{&}_maxi{
    max-width: $max-width-maxi;
  } //_maxi
} //section-limit

.section-spacer{
  width:100%;
  height:$section-spacer-height;
}

.row-spacer{
  width:100%;
  float:$right;
  height:$row-spacer-height;
}

.max-width-centered{
  width: $max-width;
  padding: $padding-base;
  margin: $center-margin;
}

img{
  max-width:100%;
}

ul{
  padding: 0;
  list-style-type: none;
}

.img-not-responsive{
  max-width:auto;
}

.hr-full{
  margin:0;
  padding:0;
  border:0;
  width:100%;
  height:$hr-full-height;
  background-color:$hr-full-bg;
  border-bottom:1px solid $hr-border-color;
  margin:$hr-margin;
}

.no-row-padding{
    margin:0;
    & > *{
      padding:0;
    }
}

.padding-comp-l{
  padding-#{$left}: 15px;
}

.padding-comp-r{
  padding-#{$right}: 15px;
}

.vertical-center{
  top: 50%;
  transform: translateY(-50%);
}
