@media print {
  a[href]:after {
    content: none
  }
}

.row-50{
  margin-left: -50px;
  margin-right: -50px;

  @include respond-to('medium'){
    margin-left: -15px;
    margin-right: -15px;
  } //---med
} //row-50
.col-50{
  padding-left: 50px;
  padding-right: 50px;

  @include respond-to('medium'){
    padding-left: 15px;
    padding-right: 15px;
  } //---med
} //col-50
.main-banner{
  overflow: hidden;
  position: relative;
  width: 100%;
  max-height: 50vh;

  @include respond-to('small'){
    height: 33vh;

  } //---small
  &__img{
    min-width: 100%;
    min-height: 100%;
    margin: 0 auto;


    @include respond-to('small'){
      max-width: unset;
      height: 33vh;
      top: 0;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);
    } //---small
  } //__img
} //main-banner

.main-logo{
  text-align: center;
  padding-top: 60px;
  padding-bottom: 70px;

  @include respond-to('small'){
    padding-top: 10px;
    padding-bottom: 40px;
  } //---small
} //main-logo

.main-text{
  text-align: center;

  @include respond-to('small'){
    padding-left: 15px;
    padding-right: 15px;
  } //---small
  p{
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -.02em;
    font-weight: 400;
    color: #878a8e;
    margin-bottom: 25px;

    @include respond-to('small'){
      font-size: 16px;
      line-height: 22 px;
    } //---small
  } //p
} //main-logo

.main-icons{
  &__title{
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: -.02em;
    color: #2c3034;
    line-height: 30px;

    @include respond-to('small'){
      margin-top: 50px;
      line-height: 25px;
      font-size: 18px;
    } //---small
  } //__title
  &__cell{
    text-align: center;
    padding-top: 50px;
    height: 400px;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('extra-small'){
      height: 250px;
    } //---xs
  } //__cell
  &__cell-title{
    font-size: 30px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: -.02em;
    color: #2c3034;
    margin-top: 30px;
    text-align: center;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('small'){
      font-size: 20px;
      line-height: 22px;
      margin-top: 15px;
    } //---small
  } //__cell-title
  &__img{
    width: 100%;
    height: 250px;
    max-width: 250px;
    background-position: center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('extra-small'){
      height: 130px;
    } //---xs

  } //__img
  &__overlay{
    background-color: #013;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: .15;
  } //__overlay
} //main-icons

.main-contacts{
  @include respond-to('small'){
    text-align: center;
  } //---xs

  &__title{
    margin-top: 190px;
    text-align: center;
    margin-bottom: 20px;
    font-size: 55px;
    font-weight: 700;
    letter-spacing: -.03em;
    line-height: 30px;
    color: #181818;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('small'){
      margin-top: 70px;
      font-size: 30px;
    } //---small
  } //__title
  &__info-title{
    font-size: 20px;
    font-weight: 400;
    letter-spacing: -.03em;
    line-height: 30px;
    color: #878a8e;
    margin-bottom: 10px;
    display: block;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('small'){
      margin-top: 20px;
      margin-bottom: 0px;
    } //---small
  } //__info-title
  &__info{
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    letter-spacing: -.01em;
    color: #2c3034;
    margin-bottom: 20px;
    padding-#{$right}: 30px;
    display: block;

    &.main-contacts__first-row{
      @include respond-to('small'){
        margin-top: 0;
      } //---small
    }

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('small'){
      margin: 0;
    } //---small
  } //__info
  &__first-row{
    margin-top: 105px;

    @include respond-to('small'){
      margin-top: 30px;
    } //---small
  } //__first-row
  &__text{
    margin-top: 30px;
    padding-top: 40px;
    position: relative;

    @include respond-to('medium'){
      padding-left: 15px;
      padding-right: 15px;
    } //---med
    @include respond-to('small'){
      text-align: center;
    } //---small

    &:after{
      content: '';
      height: 2px;
      max-width: 480px;
      width: 100%;
      top: 0;
      right: 0;
      position: absolute;
      background-color: #b4b6b8;

      @include respond-to('small'){
        right: 50%;
        transform: translateX(50%);
      } //---small
    } //after
    p{
      font-size: 20px;
      line-height: 29px;
      font-weight: 700;
      letter-spacing: -.03em;
      color: #878a8e;
    } //p
  } //__text
  &__img{
    padding-top: 100px;
    img{
      max-width: unset;

      @include respond-to('small'){
        max-width: 100%;
        padding-top: 0;
      } //---small
    }
  } //__img
} //main-contacts

.box{
  width: 100%;
  padding-top: $box-head-space;
  padding-bottom: $box-foot-space;
  position: relative;

  @at-root #{&}__strip{
    height: $box-strip-width;
    margin-top: $box-strip-top;

    @at-root #{&}_silver{
      background-color: $color-silver;
    } //_silver
  } //__strip

  @at-root #{&}__title{
    text-align: center;
    position: relative;
    font-size: $box-title-size;
    padding: 0 0 40px;

    @at-root #{&}_has-separator{
      &:after{
        width: 100%;
        content: '';
        height: 2px;
        box-sizing: border-box;
        border-top: 1px solid #000;
        border-bottom: 1px solid #fff;
        position: absolute;
        bottom: 0;
        #{$left}: 0;
        opacity: .15;
      }
    }

    @at-root #{&}_strong{
      font-weight: 700;
    }
  } //__title

  @at-root #{&}__sub-title{
    font-size: $box-subtitle-size;
    text-align: center;
    position: relative;
  } //__sub-title

  @at-root #{&}__content{
    font-size: $box-content-size;
    line-height: $box-content-line;
    position: relative;

    p{
      font-size: inherit;
      margin-bottom: $box-content-p-bottom;
    } //p
    @at-root #{&}_block{
      @at-root #{&}_s{
        width: $box-content-block-small;
        min-width: $box-content-block-small-min;
      } //_s
      @at-root #{&}_m{
        width: $box-content-block-medium;
        min-width: $box-content-block-medium-min;
      } //_m
      @at-root #{&}_l{
        width: $box-content-block-large;
        min-width: $box-content-block-large-min;
      } //_l
      @at-root #{&}_full{
        width: 100%;
      } //_l
    } //_block
  } //__content

  @at-root #{&}__img{
    @at-root #{&}_height{
      @at-root #{&}_full{
        max-width: unset;
        width: auto;
        height: 100%;
        position: absolute;
        top: 0;
      } //_full
    } //_height
  } //__img

  @at-root #{&}__background{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    #{$left}: 0;
    z-index: -1;

    @at-root #{&}_color{
      @at-root #{&}_black{
        background-color: $color-black;
      } //_black
      @at-root #{&}_white{
        background-color: $color-white;
      } //_white
      @at-root #{&}_peach{
        background-color: $color-peach;
      } //_peach
    } //_color
    @at-root #{&}_opacity{
      @at-root #{&}_half{
        opacity: .5;
      } //_half
      @at-root #{&}_third{
        opacity: .33;
      } //_half
    } //_opacity
  } //__background

  @at-root #{&}_bg{
    @at-root #{&}_white{
      background-color: #fff;
    } //_white
    @at-root #{&}_black{
      background-color: #000;
    } //_black
  } //_bg

  @at-root #{&}_border{
    @at-root #{&}_grey{
      border: solid $box-border-width $color-grey;
    } //_grey
  } //_border

  @at-root #{&}_align{
    @at-root #{&}_center{
      text-align: center;
    } //_center
  } //_align
} //box

footer{
  width: 100%;
  height: 100px;
  background-color: #fff;
}
